import {Component, OnInit, Input, ViewEncapsulation,ViewChild, OnDestroy} from '@angular/core'

import { Router } from '@angular/router'
import { RestApiService } from '../../shared/rest-api.service';
import { CreateUserService } from '../../services/createuser/createuser.service';
import {MatDatepicker, MatSnackBar} from '@angular/material'
import {FormControl} from '@angular/forms';
import { CommunicationService } from '../../services/communication/communication.service';
import { tap, catchError, map, delay,takeUntil } from 'rxjs/operators';
import {empty, Subject} from 'rxjs';
import { formatDate } from '@angular/common';
import { EmailValidatorService } from '../../services/emailValidator/emailValidator.service';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';


@Component({
    selector: 'add-employee-old',
    templateUrl: 'add-new-employee-new.component.html',
    encapsulation:ViewEncapsulation.None,
    styles:[`
    .custom-dp-icon {
        position: relative!important;
        top: -6px;
        left: -17px;
    }
    .passmanager {float:right; color:#E05C65;padding-left:10px;font-size:0.8rem;}
    
    `]
})

export class AddNewEmployeeComponent  implements OnDestroy{
    @ViewChild('addEmployeeForm',{static:false}) addEmployeeForm:any
    allDDL:any
    localizationDDL:any
    teamDDL:any
    levelDDL: any
    positionDDL:any
    managerDDL:any
    locationErr:boolean = false
    teamErr:boolean = false
    positionErr:boolean = false
    levelErr:boolean = false
    managerErr:boolean = false
    date = new FormControl(new Date());
    dateAvida = new FormControl()
    dateExpander = new FormControl()
    dateOpenFinance = new FormControl()
    selectedDate:any = new Date()
    selectedAvidaDate:any = null
    selectedExpanderDate:any = null
    selectedOpenFinanceDate:any = null
    selLocation:string = "0"
    selPosition:string = "0"
    selTeam:string = "0"
    selLevel:string = "0"
    selManager:string = "0"
    POSTStatus = {}
    selValLocation:string
    selValTeam:string
    selValLevel:string
    selValPosition:string
    selValManager:string
    emMail:any
    emName:any
    emSurname:any
    mouseoverSubmit
    minDate:any
    private ngUnsubscribe = new Subject()

    //@Input() employeeDetails = { name: '',surname:'',password:'', email: '', phone: '',level: '' }
    constructor(
        public restApi:RestApiService,
        public router:Router, 
        public createuser:CreateUserService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private emailValidator:EmailValidatorService,
        private sharedServices:SharedServicesService
    ){
        this.minDate = new Date()
    }
    
    ngOnInit() { 
        // this.createuser.getLocationList()
        //     .subscribe(resp => {
        //         this.localizationDDL = resp.body
        //     })

        // this.createuser.getTeamList()
        //     .subscribe(resp => {
        //         this.teamDDL = resp.body
        //     })

        // this.createuser.getPositionList()
        //     .subscribe(resp => {
        //         this.positionDDL = resp.body
        //     })

        // this.createuser.getLevelList()
        //     .subscribe(resp => {
        //         this.levelDDL = resp.body
        //     })
        this.sharedServices.getAllDDL()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.allDDL = <any> resp.body
                this.localizationDDL = this.allDDL.locations
                this.teamDDL = this.allDDL.teamsName
                this.positionDDL = this.allDDL.jobsTitle
                this.levelDDL = this.allDDL.accesses
                this.managerDDL = this.allDDL.managers

            })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    addEmployee() {
      // this.restApi.createEmployee(this.employeeDetails).subscribe((data: {}) => {
      //   this.router.navigate(['/employees-list'])
      // })
    }

    locationDDLchange(value){
        value === "0" ? this.locationErr = true : this.locationErr = false
    }

    positionDDLchange(value){
        value === "0" ? this.positionErr = true : this.positionErr = false
    }

    levelDDLchange(value){
        value === "0" ? this.levelErr = true : this.levelErr = false
    }

    teamDDLchange(value){
        value === "0" ? this.teamErr = true : this.teamErr = false
    }

    EndDateChange(ev){
        
        this.selectedDate = ev.value
    }
    AvidaDateChange(ev){
        this.selectedAvidaDate = ev.value
    }
    ExpanderDateChange(ev){
        this.selectedExpanderDate = ev.value
    }
    OpenFinanceDateChange(ev){
        this.selectedOpenFinanceDate = ev.value
    }

    submit(formValues){

        let userDetails ={
            email:formValues.emMail,
            firstName:formValues.emNxame,
            lastName:formValues.emSurname,
            dateStart:formatDate(this.selectedDate,"yyyy-MM-dd","en_PL"),
            dateStartAvida: this.selectedAvidaDate === null ? null : formatDate(this.selectedAvidaDate,"yyyy-MM-dd","en_PL"),
            dateStartExpander: this.selectedExpanderDate === null ? null : formatDate(this.selectedExpanderDate,"yyyy-MM-dd","en_PL"),
            dateStartOpenFinance: this.selectedOpenFinanceDate === null ? null : formatDate(this.selectedOpenFinanceDate,"yyyy-MM-dd","en_PL"),
            teamId:this.selTeam,
            jobTitleId:this.selPosition,
            managerId:this.selManager,
            locationId: this.selLocation,
            AccessLevelID:this.selLevel,
            isActive:false,
            password:formatDate(this.selectedDate,"yyyyMMddhhmmss","en_PL"),
            salt:null
        }

        this.communicationService.emitChange()
        this.createuser.createUser(userDetails)
            .pipe(
               catchError((err: any) => {
                    this.POSTStatus = {error:err.error}
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                this.resetForm()
                this.selManager = "0"
                this.dateAvida.setValue(null)
                this.dateExpander.setValue(null)
                this.dateOpenFinance.setValue(null)
                let text = ""
                this.snackBar.open(text, "X", {
                    duration: 3000,
                  });
            });  
    }

    checkDDL(){
        return (this.emSurname !== "" && this.emName !== "" && this.selLocation !== "0" && this.selPosition !== "0" && this.selTeam !== "0" && this.selLevel !== "0" && !this.emailInValid(this.emMail)) ? false : true
    }

    resetForm(){
        this.addEmployeeForm.reset()
        this.date = new FormControl(new Date());
        this.selLocation = "0"
        this.selValTeam = "0"
        this.selValLevel = "0"
        this.selValPosition = "0"
    }

    emailInValid(email){
        if(email === null || email === undefined || email === ""){
            return true
        } else {
            return this.emailValidator.validateGivenEmail(email)
        }
    }
    
}