import {Component, OnInit, ViewChild, ViewEncapsulation, Inject,Output,EventEmitter, OnDestroy} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { EmployeeService } from '../../services/employee/employe.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '../../../../node_modules/@angular/material';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';
import { CommunicationService } from '../../services/communication/communication.service';
import { tap, catchError, map, takeUntil } from 'rxjs/operators';
import {empty, Subject} from 'rxjs';
import { formatDate } from '../../../../node_modules/@angular/common';
import { FormControl } from '../../../../node_modules/@angular/forms';


export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

export interface UsersData {
  userId:number
  firstName:string
  lastName:string
  managerName:string
  teamName:string
  locationName:string
  accessLevelName:string
  UserAccountStatusId:number
}

export interface DialogData {
  userId: number;
}

@Component({
  selector: 'table-overview',
  styleUrls: ['employee-list.component.css'],
  templateUrl: 'employee-list.component.html',
  encapsulation:ViewEncapsulation.None,
  styles:[`
  .mat-dialog-container{
    background: #3a3f44!important;
  }
  
  .mat-option-text {
    color:white!important;
  }

  .mat-form-field-underline{
    color:white!important;
  }

  .mat-select-arrow{
    color: white!important;
  } 
  `]
})
export class EmployeeListComponent implements OnInit,OnDestroy {
  //displayedColumns: string[] = ['id', 'name', 'progress', 'color'];
  //displayedColumns: string[] = ["userId", "firstName", "lastName", "managerName", "teamName", "locationName", "accessLevelName", "isActive"]
  displayedColumns: string[] 
  // dataSource: MatTableDataSource<UserData>;
  dataSource: MatTableDataSource<UsersData>;
  //dataSource_tst:MatTableDataSource<UsersData>;
  //displayedColumns_tst: string[]
  
  // activeFilterDDL = [
  //   {key: 'true', value: 'Aktywny'},
  //   {key: 'false', value: 'Nieaktywny'}
  // ];
  activeFilterDDL = {
    true:"Aktywny",
    false:"Nieaktywny"
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  lastNameFilter = new FormControl('')
  firstNameFilter = new FormControl('')
  managerNameFilter = new FormControl('')
  teamNameFilter = new FormControl('')
  locationNameFilter = new FormControl('')
  accessLevelNameFilter = new FormControl('')
  isActiveFilter = new FormControl('')
  filterValues = {
    lastName:'',
    firstName:'',
    managerName:'',
    teamName:'',
    locationName:'',
    accessLevelName:'',
    isActive:''
  }

  filterDDL:any
  managerFilterDDL:any
  teamFilterDDL:any
  locationFilterDDL:any
  accessFilterDDL:any
  private ngUnsubscribe = new Subject()

  constructor(
    private employeeService:EmployeeService,
    public dialog:MatDialog,
    private sharedServices:SharedServicesService
  ) {
    // Assign the data to the data source for the table to render
    this.employeeService.getAllUsers()
    .pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(resp =>{
      let result = <any> resp.body
      result = result.map(item =>{
        item.managerName === null ? item.managerName = '':null;
        return item
      })
      this.dataSource = new MatTableDataSource(result);
      this.dataSource.filterPredicate = this.createFilter();
      this.displayedColumns = Object.keys(this.dataSource.filteredData[0])
      this.displayedColumns.push(this.displayedColumns.splice(this.displayedColumns.indexOf('userId'),1)[0])
      this.displayedColumns.sort(function(x,y){
        return x == 'lastName' ? -1:0
      })
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;      
    })

    this.sharedServices.getAllDDL()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resp=>{
      this.filterDDL = <any> resp.body
      this.managerFilterDDL = this.filterDDL.managers
      this.teamFilterDDL = this.filterDDL.teamsName
      this.locationFilterDDL = this.filterDDL.locations
      this.accessFilterDDL = this.filterDDL.accesses
    })
    
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

  ngOnInit() {
    this.lastNameFilter.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          lastName => {
            this.filterValues.lastName = lastName;
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )

    this.firstNameFilter.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        firstName => {
          this.filterValues.firstName = firstName;
          this.dataSource.filter = JSON.stringify(this.filterValues)
        }
      )

    this.managerNameFilter.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          managerName => {
            this.filterValues.managerName = managerName.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )

    this.teamNameFilter.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          teamName => {
            this.filterValues.teamName = teamName.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )

    this.locationNameFilter.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          locationName => {
            this.filterValues.locationName = locationName.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )

    this.accessLevelNameFilter.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          accessLevelName => {
            this.filterValues.accessLevelName = accessLevelName.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )

    this.isActiveFilter.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          isActive => {
            this.filterValues.isActive = isActive.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
          }
        )
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function(data, filter): boolean {
      let searchTerms = JSON.parse(filter);
      return data.lastName.toLowerCase().indexOf(searchTerms.lastName) !== -1
        && data.firstName.toLowerCase().indexOf(searchTerms.firstName) !== -1
        && data.managerName.toLowerCase().indexOf(searchTerms.managerName) !== -1
        && data.teamName.toLowerCase().indexOf(searchTerms.teamName) !== -1
        && data.locationName.toLowerCase().indexOf(searchTerms.locationName) !== -1
        && data.accessLevelName.toLowerCase().indexOf(searchTerms.accessLevelName) !== -1
        && data.isActive.toString().indexOf(searchTerms.isActive) !== -1
        
    }
    return filterFunction;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openUserDetails(userId){
    let dialogRef = this.dialog.open(EmployeeListDialogComponent, {
      width:'49rem',
      data:{
        user:userId
      },
    })

    dialogRef.afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resp=>{
      if(resp){
        let newVal = this.dataSource.filteredData.filter(itm=> itm.userId === resp.UserId)[0]
        newVal.UserAccountStatusId = resp.UserAccountStatusId
        newVal.managerName = resp.ManagerId ? resp.ManagerId : newVal.managerName
        newVal.teamName = resp.TeamId ? resp.TeamId : newVal.teamName
        newVal.locationName = resp.LocationId ? resp.LocationId : newVal.locationName
        newVal.accessLevelName = resp.AccessLevelId ? resp.AccessLevelId : newVal.accessLevelName
      }
    })
  }

  clear(){
    this.lastNameFilter.setValue('')
    this.firstNameFilter.setValue('')
    this.managerNameFilter.setValue('')
    this.teamNameFilter.setValue('')
    this.locationNameFilter.setValue('')
    this.accessLevelNameFilter.setValue('')
    this.isActiveFilter.setValue('')
  }

}

@Component({
  selector: 'employee-dialog',
  templateUrl: 'employee-list-dialog.component.html',
  encapsulation:ViewEncapsulation.None,
  styles:[`
  /*#3a3f44*/
  .mat-dialog-container{
    background: #3a3f44!important;
  }
  .mat-option-text {
    color:white!important;
  }

  .mat-form-field-underline{
    color:white!important;
  }

  .mat-select-arrow{
    color: white!important;
  }
  `]
})

export class EmployeeListDialogComponent implements OnDestroy {
  @ViewChild("toggleBtn",{static:false}) toggleBtn;
  userObj:any
  userDetails:any=[]
  deepDetails:any
  allDDL:any
  jobsTitles:any
  contracts:any
  teamsName:any
  managers:any
  locations:any
  accesses:any
  jobId:any
  teamId:number
  contId:any
  managerId:any
  locationId:number
  accessId:number
  showLastName:boolean
  showFirstName:boolean
  showEmail:boolean
  showPhone:boolean
  mouseoverLastName:boolean
  mouseoverFirstName:boolean
  mouseoverEmail:boolean
  dateStartChange:boolean = false
  dateStart:any
  dateAvidaChange:boolean = false
  dateAvida:any
  dateExpanderChange:boolean = false
  dateExpander:any
  dateOpenFinance:any
  dateOpenFinanceChange:boolean = false
  changeFlag:boolean = false
  activeFlagChange:boolean = false
  stringInfo:any
  mouseoverJob:boolean
  mouseoverTeam:boolean
  mouseoverManager:boolean
  mouseoverLocation:boolean
  mouseoverAccess:boolean
  mouseoverPhone:boolean
  enableJob:boolean
  enableCont:boolean
  enableTeam:boolean
  enableManager:boolean
  enableLocation:boolean
  enableAccess:boolean
  mouseoverActive:boolean
  showActive:boolean
  mouseoverDateStart:boolean
  showStartDate:boolean
  mouseoverDateAvida:boolean
  showAvidaDate:boolean
  mouseoverDateExpander:boolean
  showExpanderDate:boolean  
  mouseoverDateOpenFinance:boolean
  showOpenFinenceDate:boolean
  labelPosition='before'
  private ngUnsubscribe = new Subject()
  showOpenFinanceDate:boolean
  isActive:any
  lastName:any
  firstName:any
  email:any
  phone:any
  contract:any
  datesStart:any
  statusDesc:any
  baseUserStatus:any
  archiveUserStatus:any
  btnColor:any
  toggle:any
  

  constructor(
    public dialogRef:MatDialogRef<EmployeeListDialogComponent>,
    @Inject (MAT_DIALOG_DATA) public data:DialogData,
    private employeeService:EmployeeService,
    private sharedServices:SharedServicesService,
    private communicationService:CommunicationService,
    private snackBar:MatSnackBar
  ){
    this.userObj = data
    this.employeeService.getUserDetails(this.userObj.user)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resp => {
      this.userDetails = <any> resp.body
      this.baseUserStatus = this.userDetails.userAccountStatusId
      this.archiveUserStatus = this.baseUserStatus
      this.deepDetails = <any> resp.body
      this.dateStart = this.deepDetails.datesStart.DateStartGeneral
      this.dateAvida = this.deepDetails.datesStart.DateStartAvida
      this.dateExpander = this.deepDetails.datesStart.DateStartExpander
      this.dateOpenFinance = this.deepDetails.datesStart.DateStartOpenFinance
      this.jobId = this.deepDetails.jobTitle.id.toString()
      this.teamId = this.deepDetails.team.id.toString()
      this.contId = this.deepDetails.contractType.id
      this.contId = this.contId === null ? this.contId : this.contId.toString()
      this.managerId = this.deepDetails.manager.id
      this.managerId = this.managerId === null ? this.managerId : this.managerId.toString()
      this.locationId = this.deepDetails.location.id.toString()
      this.accessId = this.deepDetails.accessLevel.id.toString()
      //this.accessId = this.deepDetails.accessLevel
    })
  }

  disableAnimation = true;
  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
    setTimeout(()=> {
      this.userStatus(this.baseUserStatus)
    },300)
    
  } 
  
  ngOnDestroy(){
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

  cancel(){
    this.dialogRef.close()
  }

  isOpened(){
    this.sharedServices.getAllDDL()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(resp=>{
      this.allDDL = <any> resp.body
      this.jobsTitles = this.allDDL.jobsTitle
      this.teamsName = this.allDDL.teamsName
      this.managers = this.allDDL.managers
      this.locations = this.allDDL.locations
      this.accesses = this.allDDL.accesses
      this.contracts = this.allDDL.contractTypes
    })
  }


  toggleStatus(id){
    switch(id){
      case 0:{
        this.baseUserStatus = 1
        this.btnColor = "accent"
        this.userStatus(this.baseUserStatus)
        this.toggleBtn.checked = false
        break;
      }      
      case 1:{
        this.baseUserStatus = 3
        this.btnColor = "warn"
        this.userStatus(this.baseUserStatus)
        this.toggleBtn.checked = false
        break;
      }
      case 2:{
        this.baseUserStatus = 3
        this.btnColor = "warn"
        this.userStatus(this.baseUserStatus)
        this.toggleBtn.checked = false
        break;
      }
      case 3:{
        this.baseUserStatus = this.baseUserStatus === this.archiveUserStatus? 1: this.archiveUserStatus
        this.userStatus(this.baseUserStatus)
        this.btnColor = "accent"
        this.toggleBtn.checked = this.baseUserStatus === 2? false: this.baseUserStatus === 1? true:false
        //this.toggleBtn.checked = false
        break;
      }
    }
  }

  userStatus(id){
    switch(id){
      case 0:{
        this.statusDesc = ''
        this.toggleBtn.checked = false;
        break;
      }
      case 1:{
        this.statusDesc = null
        this.toggleBtn.checked = false;
        break;
      }
      case 2:{
        this.statusDesc = true
        this.toggleBtn.checked = true;
        break;
      }
      case 3:{
        this.statusDesc = false
        this.btnColor = "warn"
        this.toggleBtn.checked = true;
        break;
      }
    }

  }

  update(form){
    let formInfo = {
      UserId:form.userId,
      JobTitleId:this.jobId,
      TeamId: this.teamId,
      ManagerId: this.managerId,
      LocationId: this.locationId,
      AccessLevelId: this.accessId,
      UserAccountStatusId: this.baseUserStatus,
      DateStartGeneral: formatDate(form.datesStart.DateStartGeneral,"yyyy-MM-dd","en_PL"),
      DateStartAvida: form.datesStart.DateStartAvida ? null:formatDate(form.datesStart.DateStartAvida,"yyyy-MM-dd","en_PL"),
      DateStartExpander: form.datesStart.DateStartExpander === null ? null : formatDate(form.datesStart.DateStartExpander,"yyyy-MM-dd","en_PL"),
      DateStartOpenFinance: form.datesStart.DateStartOpenFinance === null ? null : formatDate(form.datesStart.DateStartOpenFinance,"yyyy-MM-dd","en_PL"),
      PhoneNr: this.userDetails.phoneNr,
      ContractTypeId: this.contId         
    }

    if(this.changeFlag){
      this.stringInfo = {
        UserId:form.userId,
        JobTitleId:this.jobsTitles[this.jobId],
        TeamId: this.teamsName[this.teamId],
        ManagerId: this.managers[this.managerId],
        LocationId: this.locations[this.locationId],
        AccessLevelId: this.accesses[this.accessId],
        UserAccountStatusId: this.baseUserStatus  
      }
    } else {
      this.stringInfo = {
        UserId:form.userId,
        UserAccountStatusId: this.baseUserStatus  
      }      
    }
    
  
    this.employeeService.editUser(formInfo)
        .pipe(
          catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
          let respObj = JSON.parse(resp.body)
          let message = respObj.message
          let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
          if(respObj.isSuccess){
            this.dialogRef.close(this.stringInfo)
          }
          this.snackBar.open(message, "X", {
              duration: 3000,
              panelClass: messageClass
          });
        });

  }

  getTooltipText(type,val){
    if(val !== undefined){
      if(type ==="jobTitle"){
        if(this.jobsTitles !== undefined){
          return this.jobsTitles[val]
        }
      }
      if(type ==="teamId"){
        if(this.teamsName !== undefined){
          return this.teamsName[val]
        }
      }
      if(type ==="managerId"){
        if(this.managers !== undefined){
          return this.managers[val]
        }
      }
      if(type ==="locations"){
        if(this.locations !== undefined){
          return this.locations[val]
        }
      }
      if(type ==="accesses"){
        if(this.accesses !== undefined){
          return this.accesses[val]
        }
      }
    }
  }
}