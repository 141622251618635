import { Injectable, Output,EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';



@Injectable()
export class CommunicationService {
    //@Output() message$: EventEmitter<any> = new EventEmitter()
    constructor() { }

    private emitChangeSource = new Subject<any>();
    private emitExpPanelChange = new Subject()
    private emitDrawerFormChange = new Subject()
    private emitManualRefreshChange = new Subject()
    private refreshList = new Subject()

    changeEmitted$ = this.emitChangeSource.asObservable();
    changeEmittedPanel$ = this.emitExpPanelChange.asObservable()
    changeEmittedDrawerForm$ = this.emitDrawerFormChange.asObservable()
    refreshManual$ = this.emitManualRefreshChange.asObservable()
    refreshList$ = this.refreshList.asObservable()
    errorEvent: Subject<boolean> = new Subject<boolean>();

    refreshRoot = new EventEmitter()

    emitChange() {
        this.emitChangeSource.next();
    }

    emitExpPanel(flag){
        this.emitExpPanelChange.next(flag)
    }

    emitDrawerForm(flag){
        this.emitDrawerFormChange.next(flag)
    }
    
    emitManualRefresh(id){
        this.emitManualRefreshChange.next(id)
    }

    emitRefreshList(data){
        this.refreshList.next(data)
    }

    logoutService(event){
        this.errorEvent.next(true)
    }

}