import { Component, ViewChild, OnDestroy } from "@angular/core";
import { CreateUserService } from '../../services/createuser/createuser.service';
import { CommunicationService } from '../../services/communication/communication.service';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';
import { Subject, empty } from '../../../../node_modules/rxjs';
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { KeyValue, formatDate } from '../../../../node_modules/@angular/common';


@Component({
    selector:'add-employee',
    templateUrl: './add-new-employee-new.component.html',
})

export class AddNewEmployeeNewComponent implements OnDestroy {

    @ViewChild('newEmployeeObj',{static:false}) newEmployeeObj
    allDDL =[]
    localizationDDL = []
    teamDDL = []
    positionDDL = []
    levelDDL = []
    managerDDL = []
    contractDDL = []
    minDate:any
    POSTStatus:any
    private ngUnsubscribe = new Subject()

    newEmployee = new FormGroup({
        name: new FormControl('',Validators.required),
        surname: new FormControl('',Validators.required),
        email: new FormControl('',[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
        phone: new FormControl('',Validators.required),
        contract: new FormControl('',Validators.required),
        startDate: new FormControl('',Validators.required),
        location: new FormControl('',Validators.required),
        team: new FormControl('',Validators.required),
        manager: new FormControl('',Validators.required),
        position: new FormControl('',Validators.required),
        level: new FormControl('',Validators.required),
        startAvidaDate: new FormControl(),
        startExpanderDate:new FormControl(),
        startOpenFinanceDate:new FormControl()
    })


    constructor(
        public createuser:CreateUserService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private sharedServices:SharedServicesService
    ){}

    ngOnInit(){
        this.minDate = new Date()
        this.sharedServices.getAllDDL()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let arr = <any> resp.body
            this.localizationDDL = arr.locations
            this.teamDDL = arr.teamsName
            this.positionDDL = arr.jobsTitle
            this.levelDDL = arr.accesses
            this.managerDDL = arr.managers
            this.contractDDL = arr.contractTypes            
        })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
    }

    markUnTouched(){
        this.newEmployee.markAsUntouched()
    }

    markTouched(){
        this.newEmployee.markAllAsTouched()
    }

    addNewEmployee(){
        let formData = {
            "Email": this.newEmployee.value.email,
            "FirstName": this.newEmployee.value.name,
            "LastName": this.newEmployee.value.surname,
            "DateStart": this.newEmployee.value.startDate === null ? null : formatDate(this.newEmployee.value.startDate,"yyyy-MM-dd","en_PL"),
            "DateStartAvida": this.newEmployee.value.startAvidaDate === null ? null : formatDate(this.newEmployee.value.startAvidaDate,"yyyy-MM-dd","en_PL"),
            "DateStartExpander": this.newEmployee.value.startExpanderDate === null ? null : formatDate(this.newEmployee.value.startExpanderDate,"yyyy-MM-dd","en_PL"),
            "DateStartOpenFinance": this.newEmployee.value.startOpenFinanceDate === null ? null : formatDate(this.newEmployee.value.startOpenFinanceDate,"yyyy-MM-dd","en_PL"),
            "TeamId": this.newEmployee.value.team,
            "JobTitleId": this.newEmployee.value.position,
            "ManagerId": this.newEmployee.value.manager,
            "LocationId": this.newEmployee.value.location,
            "AccessLevelId": this.newEmployee.value.level,
            "PhoneNr": this.newEmployee.value.phone,
            "ContractTypeId": this.newEmployee.value.contract    
        }

        this.communicationService.emitChange()
        this.createuser.createUser(formData)
            .pipe(
               catchError((err: any) => {
                    this.POSTStatus = {error:err.error}
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                let respObj = JSON.parse(<any> resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                if(respObj.isSuccess){
                    this.newEmployeeObj.resetForm();
                }
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
            }); 
    }


}