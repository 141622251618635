import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Subject, empty, forkJoin, Observable } from '../../../../node_modules/rxjs';
import { MatSnackBar, ThemePalette } from '../../../../node_modules/@angular/material';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { ManageBaseService } from '../../services/managebase/manage-base.service';
import { KeyValue } from '../../../../node_modules/@angular/common';
import { ngIfAnimation } from '../../animations/animations';
import { CommunicationService } from '../../services/communication/communication.service';
import { FormControl, FormGroup, Validators } from '../../../../node_modules/@angular/forms';
import { RecordService } from '../../services/record/record.service';
import { EmployeeService } from '../../services/employee/employe.service';

@Component({
    selector:'manage-base',
    templateUrl: '/manage-base.component.html',
    styles:[`
    .mat-progress-bar.mat-progress-custom.mat-accent .mat-progress-bar-buffer{
        background-color:transparent!important;
    }
    .mat-progress-bar.mat-progress-custom.mat-accent .mat-progress-bar-background{
        fill:#b9f6ca00!important;
    }
    .tSelect div.mat-select-arrow-wrapper {
        display: none;
    }
    `],
    encapsulation:ViewEncapsulation.None,
    animations: [
        ngIfAnimation
    ]
})

export class ManageBaseComponent implements OnDestroy{
    private ngUnsubscribe = new Subject()
    teamsDDL:any = []
    teamDDLLength:number
    fieldsDDL1:any = []
    fieldsDDL2:any = []
    fieldsDDL3:any = []
    temp_fieldsDDL:any=[]
    campaignArr:any = []
    campaignListArr:any = []
    recordsPriority:any = []
    progress:boolean = false
    removable = true;
    teamsDDLSelect = new FormControl()
    campaignDDLSelect = new FormControl()
    campaigns:any =[]
    searchType:string = "PESEL"
    recordsToMofify:any = []
    usersList:any = []
    searchTypeError:boolean = false

    searchClients = new FormGroup({
        searchType: new FormControl('1',Validators.required),
        campaign: new FormControl('',Validators.required),
        searchValues: new FormControl('',Validators.required)
    })

    campaignDetails =[]
    campaignTeams=[]

    constructor(
        private snackBar:MatSnackBar,
        private sharedServices: SharedServicesService,
        private managebese: ManageBaseService,
        private communicationService: CommunicationService,
        private record: RecordService,
        private employeeService: EmployeeService
    ){}


    ngOnInit(){
        this.sharedServices.getDDLTeams()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.teamsDDL = <any>resp.body
            this.teamDDLLength = Object.keys(this.teamsDDL.teams).length
        })
        this.forkCampaignStreams()
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
    }

    selectTeam(event){
        this.recordsPriority = []
        this.getCampaignsTeamPriority(event.value)
    }

    assignTeamToCampaign(event,id){

    }

    tabChange(event){
        switch(event.tab.textLabel){
            case "0":{
                this.forkCampaignStreams()
                break;
            }
            case "1":{
                if(this.teamsDDL.length === 0){
                    this.sharedServices.getDDLTeams()
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(resp=>{
                            this.teamsDDL = <any>resp.body
                            this.teamDDLLength = Object.keys(this.teamsDDL).length
                        })
                }
                break;
            }
            case "2":{
                if(this.teamsDDL.length === 0){
                    this.sharedServices.getDDLTeams()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(resp=>{
                        this.teamsDDL = <any>resp.body
                        this.teamDDLLength = Object.keys(this.teamsDDL).length
                    })
                }
                
                break;
            }
            case "3":{
                this.sharedServices.getDDLCampaignList()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    let arr = <any> resp.body
                    this.campaigns = arr.campaigns           
                })
                break;
            }
        }
    }

    formatLabel(value: number) {
        return value
    }

    getCampaignsTeamPriority(id){
        this.progress = !this.progress
        this.managebese.getCampaignsTeamPriority(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.campaignArr = <any> resp.body
            this.progress = !this.progress
            this.campaignListArr = this.campaignArr.map(function(itm){
                return {
                    campaignId: itm.campaignId,
                    campaignName:itm.campaignName
                }
            })
        })
    }

    changeCampaignsPriority(){
        this.communicationService.emitChange()
        this.managebese.updateCampaignsTeamsPriority(this.campaignArr)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
            }); 
    }

    selectCampaign(event){
        this.recordsPriority = []
        this.recordsPriority = this.campaignArr.filter(
            x => x.campaignId == event.value
        )
        this.managebese.getCampaignsRecordPriorityDDL(event.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let obj = <any> resp.body
            let pkd = obj.pkd
            let prov = obj.province

            let pkdArr = pkd.map(function(itm){
                return{
                    parent: "pkd",
                    value:itm
                }
            })

            let provArr = prov.map(function(itm){
                return {
                    parent:"province",
                    value:itm
                }
            })
            this.fieldsDDL1 = pkdArr.concat(provArr)
            this.fieldsDDL2 = pkdArr.concat(provArr)
            this.fieldsDDL3 = pkdArr.concat(provArr)
            this.temp_fieldsDDL = this.fieldsDDL1
        })
    }

    getCampaignDetails(){
        this.managebese.getCampaignsDetails()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let obj = <any> resp.body
        })
    }

    getCampaignTeams(){
        this.managebese.getCampaignsTeams()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let obj = <any> resp.body
        })
    }

    forkCampaignStreams(){
        this.managebese.doMultipleCalls()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let main = resp[0].body
            let child = resp[1].body.campaignTeamsList
            for(var i=0;i<child.length;i++){
                let obj = child[i].teamsId
                let newArr = []
                for(var z = 0;z<obj.length;z++){
                    newArr.push(obj[z].toString())
                }
                child[i].teamsId = newArr
            }
            
            this.campaignDetails = main.map(x=>{
                return {
                    campaignId: x.campaignId,
                    dateStart: x.dateStart,
                    name: x.name,
                    nrRecordsDokumenty: x.nrRecordsDokumenty,
                    nrRecordsFresh: x.nrRecordsFresh,
                    nrRecordsProspekt: x.nrRecordsProspekt,
                    nrRecordsRezygnacja: x.nrRecordsRezygnacja,
                    nrRecordsStyczen: x.nrRecordsStyczen,
                    nrRecordsWithCreditApp: x.nrRecordsWithCreditApp,
                    nrRecordsWithOpenCreditApp: x.nrRecordsWithOpenCreditApp,
                    totalNrRecords: x.totalNrRecords,
                    teams:child.filter(y=>y.campaignId == x.campaignId)[0] === undefined ? [] : child.filter(y=>y.campaignId == x.campaignId)[0].teamsId
                }
            })
        })
    }

    changeRecordsPriority(){
        this.campaignArr = this.campaignArr.splice(this.campaignArr.findIndex(itm=>itm.campaignId === this.recordsPriority.campaignId),1)
        this.communicationService.emitChange()
        this.managebese.updateCampaignsRecordsPriority(this.campaignArr[0])
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
                  this.campaignArr = []
                  this.campaignListArr = []
                  this.recordsPriority = []
                  this.teamsDDLSelect.reset()
                  this.campaignDDLSelect.reset()
            }); 
    }

    updateTeamAssignment(id,event){
        let tempArr = []
        let teamsArr = event.value
        for(var i=0; i<teamsArr.length;i++){
            let val = Number(teamsArr[i])
            tempArr.push(val)
        }

        let form = {
            CampaignID: id,
            TeamIDs:tempArr
        }

        this.managebese.updateCampaignsTeams(form)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
            });
        
    }

    recalculate(val,id){
        switch(id){
            case 1:{
                this.fieldsDDL1 = this.temp_fieldsDDL.filter(x=>x.parent.toLowerCase() === val.toLowerCase())
                break;
            }
            case 2:{
                this.fieldsDDL2 = this.temp_fieldsDDL.filter(x=>x.parent.toLowerCase() === val.toLowerCase())
                break;
            }
            case 3:{
                this.fieldsDDL3 = this.temp_fieldsDDL.filter(x=>x.parent.toLowerCase() === val.toLowerCase())
                break;
            }
        }
    }

    resetDDls(id){
        switch(id){
            case 1:{
                this.fieldsDDL1 = []
                break;
            }
            case 2:{
                this.fieldsDDL2 = []
                break;
            }
            case 3:{
                this.fieldsDDL3 = []
                break;
            }
        }
    }

    changeSearchTypeLabel(event){
        switch(event.value){
            case "1":{
                this.searchType = "PESEL"
                this.searchTypeError = false
                break;
            }
            case "0":{
                this.searchType = "NIP"
                this.searchTypeError = false
                break;
            }
        }
    }

    getUserName(val){
        let userName = null
        if(val !== null){
            val = Number(val)
            let user = this.usersList.filter(x=>x.userId === val)[0]
            userName = user.lastName + " " + user.firstName
        }
        return userName
    }

    search(){
        this.searchTypeError = false
        this.recordsToMofify = []
        this.employeeService.getAllUsers()
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
          let result = <any> resp.body 
          this.usersList = result    
        })

        this.record.getRecordsToModify(this.searchClients.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let main = <any>resp.body
            
            for(var i=0;i<main.length;i++){
                let activeFlagValue = main[i].recordStageId === 0 ? true:false
                let activeFlag = {
                    IsActive:activeFlagValue
                }
                main[i].assignedToUserId = main[i].assignedToUserId !== null ? main[i].assignedToUserId.toString() : main[i].assignedToUserId
                let finalObj = Object.assign(main[i],activeFlag)
                this.recordsToMofify.push(finalObj)
            }
        })
    }

    changeFlagInArr(event,id){
        let index = this.recordsToMofify.map(x=>x.recordId).indexOf(id)
        this.recordsToMofify[index].recordStageId = event.checked ? 0:13
    }

    modifyRecordAssignment(){
        let obj = this.recordsToMofify
        let tempArr = []
        for(var i=0;i<obj.length;i++){
            let itm = {
                RecordId:obj[i].recordId,
                AssignedToUserId:Number(obj[i].assignedToUserId),
                IsActive:obj[i].IsActive
            }
            tempArr.push(itm)
        }
        let form = {
            ListRecordsToUpdate:tempArr
        }
        
        this.record.updateRecords(form)
            .pipe(
               catchError((err: any) => {
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
                  if(respObj.isSuccess){
                    this.recordsToMofify = []
                    this.searchClients.reset()
                    this.searchTypeError = true
                  }
            });
    }


    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}