import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './user/login.component';
import { NavBarComponent, BottomSheetDoc, BottomSheetNews, BottomSheetSalary, TwoFactorAuthDialog } from './nav/nav-bar.component';
import { HomePageComponent } from './homepage/home-page.component';
import { MatRippleModule, MatTabsModule, MatRadioModule, MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule, MatNativeDateModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule, MatStepperModule, MatIconModule, MatSidenavModule, MatTreeModule, MatBottomSheetModule, MatNavList, MatListModule, MatBadgeModule, MatCheckboxModule, MatMenuModule, MatButtonToggleModule, MatProgressSpinnerModule, MatSnackBarModule, MatTooltipModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, NativeDateModule, MatDialog, MatSlideToggleModule, MatDialogModule, MatDialogContent, MatProgressBarModule, MatChipsModule, MAT_HAMMER_OPTIONS, MatGridListModule, MatCardModule, MatSliderModule } from '@angular/material'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import { ClientContactDetailsComponent, DeleteFileDialog, EditCreditFormDialog } from './clientcontactdetails/client-contact-details.component';
import { ClientContactResultComponent } from './clientcontactdetails/contactresult/client-contact-result.component';
import { CalendarResultComponent } from './clientcontactdetails/contactresult/calendar/calendar-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddNewClientComponent } from './addnewclient/add-new-client.component';
import { AuthService } from './services/authservice/auth.service';
import { RouterModule } from '@angular/router';
import { AddNewEmployeeComponent } from './controlpanel/employeeaddnew/add-new-employee.component';
// import { EmployeesListComponent } from './employee/employees-list.component';
import { EmployeeEditComponent } from './employeeedit/employee-edit.component';
import { ControlPanelComponent } from './controlpanel/control-panel.component';
import { EmployeeListComponent, EmployeeListDialogComponent } from './controlpanel/employeelist/employee-list.component';
import { UploadBaseComponent } from './controlpanel/uploadbase/upload-base.component';
import { BaseHistoryComponent } from './controlpanel/basehistory/base-history.component';
import { AddClientComponent } from './controlpanel/addclient/add-client.component';
import { ClientListComponent } from './controlpanel/clientslist/clients-list.component';
import { ClientListItemsComponent } from './controlpanel/clientslist/client-list-items/client-list-items.component';
import { DictionariesComponent } from './controlpanel/dictionaries/dictionaries.component';
import { PermissionsListComponent } from './controlpanel/persmissionslist/permissions-list.component';
import { PermissionsListDetailsComponent } from './controlpanel/persmissionslist/permissionslistdetails/permissions-list-details.component';
import { CallbackPanelComponent } from './homepage/callbackpanel/callback-panel.component';
import {ContextMenuDialog2 } from './controlpanel/editdoctree/new-doc-tree.component';
import { GetNewRecordComponent } from './homepage/getnewrecord/get-new-record.component';
import { ManualRecordsComponent } from './homepage/manualrecords/manual-records.component';
import { Error404Component } from './errors/404.component';
import { CommunicationService } from './services/communication/communication.service';
import { DecodeTokenService } from './services/authservice/decodetoken.service';
import { TokenService } from './services/tokenservice/tokenservice.service';
import { JwtInterceptor } from './services/jwtinterceptor/jwt.interceptor';
import { GetUserCallback } from './services/getusercallback/getusercallback.service';
import { PasswordManagerComponent } from './passwordmanager/password-manager.component';
import { ValidateGuidService } from './services/validateGUID/validate-guid.service';
import { CreateUserService } from './services/createuser/createuser.service';
import { EmailValidatorService } from './services/emailValidator/emailValidator.service';
import { NewsPanelComponent } from './controlpanel/newspanel/news-panel-admin.component';
import { NewsPanelService } from './services/newspanel/newspanel.service';
import { NewsPanelAdminComponent } from './controlpanel/newspanel/newspanelallmessages/news-panel-admin-all.component';
import { ConvertToHTML } from './pipes/convertToHTML.pipe';
import { SharedServicesService } from './services/sharedservices/sharedservices.service';
import { EmployeeService } from './services/employee/employe.service';
import { PermissionsService } from './services/permissions/permissions.service';
import { HttpErrorInterceptor } from './services/jwtInterceptor/errors.interceptor';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker'
import { RecordService } from './services/record/record.service';
import { ProductTabComponent } from './homepage/producttab/product-tab.component';
import { FileService } from './shared/file.service';
import { NumericInput } from './directives/input-numbers-only.directive';
import { InputMaxLengthDirective } from './directives/input-maxLength.directive';
import { LeaveRequestComponent } from './leaverequest/leave-request.component';
import { LeaveRequestsComponent } from './controlpanel/leaverequests/leave-requests.component';
import { InvoiceListComponent } from './controlpanel/invoicelist/invoice-list.component';
import { EmployeeSplitComponent } from './controlpanel/employeesplit/employee-split.component';
import { TalkTimeComponent, TalkTimeDialog } from './controlpanel/talktime/talk-time.component';
import { SalaryFormComponent } from './controlpanel/salaryform/salary-form.component';
import { InvoiceService } from './services/invoice/invoice.service';
import { SalaryService } from './services/salary/salary.service';
import { HolidayService } from './services/holiday/holiday.service';
import { FooterComponent } from './footer.component';
import { AllowedIpComponent } from './controlpanel/allowedIp/allowed-ip.component';
import { AccountService } from './services/account/account.service';
import { AddNewEmployeeNewComponent } from './controlpanel/employeeaddnew/add-new-emplyee-new.component';
import { AllowedIPService } from './services/allowedip/allowed-ip.service';
import { ManageBaseComponent } from './controlpanel/managebase/manage-base.component';
import { ManageBaseService } from './services/managebase/manage-base.service';
import { MenuPanelComponent, ContextMenuDialog } from './controlpanel/editdoctree/menu-panel/menu-panel.component';
import { DocTreeService } from './services/doctree/doctree.service';
import { NewDocTreeComponent } from './controlpanel/editdoctree/new-doc-tree.component';
import { RecursiveSearchPipe } from './pipes/recursive.pipe';




@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginComponent,
    NavBarComponent,
    HomePageComponent,
    ClientContactDetailsComponent,
    ClientContactResultComponent,
    CalendarResultComponent,
    AddNewClientComponent,
    AddNewEmployeeComponent,
    AddNewEmployeeNewComponent,
    // EmployeesListComponent,
    EmployeeEditComponent,
    ControlPanelComponent,
    EmployeeListComponent,
    UploadBaseComponent,
    BaseHistoryComponent,
    AddClientComponent,
    ClientListComponent,
    ClientListItemsComponent,
    DictionariesComponent,
    PermissionsListComponent,
    PermissionsListDetailsComponent,
    CallbackPanelComponent,
    GetNewRecordComponent,
    ManualRecordsComponent,
    BottomSheetDoc,
    BottomSheetNews,
    BottomSheetSalary,
    TalkTimeDialog,
    Error404Component,
    PasswordManagerComponent,
    NewsPanelComponent,
    NewsPanelAdminComponent,
    ConvertToHTML,
    RecursiveSearchPipe,
    EmployeeListDialogComponent,
    ProductTabComponent,
    LeaveRequestComponent,
    LeaveRequestsComponent,
    InvoiceListComponent,
    EmployeeSplitComponent,
    TalkTimeComponent,
    SalaryFormComponent,
    AllowedIpComponent,
    ManageBaseComponent,
    MenuPanelComponent,
    NewDocTreeComponent,
    DeleteFileDialog,
    EditCreditFormDialog,
    TwoFactorAuthDialog,
    ContextMenuDialog,
    ContextMenuDialog2,
    NumericInput,
    InputMaxLengthDirective

    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatRippleModule,
    MatTabsModule,
    MatRadioModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatStepperModule,
    MatIconModule,
    MatSidenavModule,
    MatTreeModule,
    MatBottomSheetModule,
    MatListModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatGridListModule,
    MatCardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSliderModule,
    MatButtonToggleModule
  ],
  providers: [
    AuthService,
    CommunicationService,
    DecodeTokenService,
    TokenService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    GetUserCallback,
    ValidateGuidService,
    CreateUserService,
    EmailValidatorService,
    NewsPanelService,
    SharedServicesService,
    EmployeeService,
    PermissionsService,
    RecordService,
    FileService,
    InvoiceService,
    SalaryService,
    HolidayService,
    AccountService,
    AllowedIPService,
    ManageBaseService,
    DocTreeService,
    RecursiveSearchPipe,
    {provide:MAT_DATE_LOCALE,useValue:'pl-PL'},
    {provide:HTTP_INTERCEPTORS,
    useClass:HttpErrorInterceptor,
    multi:true
    },
    {
      provide:MAT_HAMMER_OPTIONS,
      useValue:{
        cssProps:{
          userSelect:true
        }
      }
    }
    

  ],
  bootstrap: [AppComponent],
    entryComponents:[
      BottomSheetDoc,
      BottomSheetNews,
      BottomSheetSalary,
      EmployeeListDialogComponent,
      DeleteFileDialog,
      EditCreditFormDialog,
      TwoFactorAuthDialog,
      TalkTimeDialog,
      ContextMenuDialog,
      ContextMenuDialog2
  ],

})
export class AppModule { }
