import { Component, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { SalaryService } from '../../services/salary/salary.service';
import { catchError, takeUntil } from '../../../../node_modules/rxjs/operators';
import { empty, Subject } from '../../../../node_modules/rxjs';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';
import { formatDate } from '../../../../node_modules/@angular/common';


@Component({
    selector:'salary-form',
    templateUrl:"./salary-form.component.html"
})

export class SalaryFormComponent implements OnDestroy {

    @ViewChild('newSalaryFormObj',{static:false}) newSalaryFormObj
    @ViewChild('salaryListObj',{static:false}) salaryListObj

    dateEnd
    dateStartMax
    teamsDDL:any = []
    userDDL:any = []
    salaryListArr =[]
    currentYear:any
    previousYear:any
    yearArr:any = []
    private ngUnsubscribe = new Subject()

    constructor(
        private snackBar:MatSnackBar,
        private salary: SalaryService,
        private sharedservices:SharedServicesService
    ){}

    salaryinvoiceForm = new FormGroup({
        team: new FormControl('',Validators.required),
        employee: new FormControl('',Validators.required),
        monthDate: new FormControl('',Validators.required),
        yearDate: new FormControl('',Validators.required),
        delay: new FormControl(),
        advance: new FormControl(),
        basegross: new FormControl('',Validators.required),
        commission: new FormControl('',Validators.required)

    })

    salaryList = new FormGroup({
        team: new FormControl('',Validators.required),
        periodFrom: new FormControl('',Validators.required),
        periodTo: new FormControl('',Validators.required)
    })

    ngOnInit(){
        this.sharedservices.getDDLTeams()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.teamsDDL = <any>resp.body
        })

        this.currentYear = (new Date()).getFullYear()
        this.previousYear = (new Date()).getFullYear() - 1
        this.yearArr = {
            0: this.currentYear,
            1: this.previousYear
        }
    }

    getUserDDL(event){
        this.sharedservices.getDDLTeamUsers(event.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.userDDL = <any>resp.body
        })      
    }

    addNewsalary(){

        let form = {
            "UserId": this.newSalaryFormObj.value.employee,
            "SettlementPeriod": this.newSalaryFormObj.value.yearDate + "-" + this.newSalaryFormObj.value.monthDate + '-01',
            "ContractType":"",
            "NrMinutesLate": this.newSalaryFormObj.value.delay,
            "AdvanceOnPayment": this.newSalaryFormObj.value.advance,
            "BasicSalaryGross": this.newSalaryFormObj.value.basegross,
            "CommissionSalaryGross": this.newSalaryFormObj.value.commission       
        }
        this.salary.addPayroll(form)
        .pipe(
            catchError((err: any) => {
                  console.log(err)
                  this.snackBar.open(err.error, "X", {
                      duration: 10000,
                      panelClass: "bg-danger"
                    });
                  return empty()
              }),
              takeUntil(this.ngUnsubscribe)
          )
          .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                if(respObj.isSuccess){
                    this.newSalaryFormObj.resetForm()
                }
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
          });
    }

    deleteSalary(val){
        this.salary.deletePayroll(val)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            this.salaryListArr.splice(this.salaryListArr.findIndex(itm=>itm.payrollId === val),1)
            let mess = <any> resp
            this.snackBar.open(mess.message, "X", {
                duration: 10000,
                panelClass: mess.isSuccess? "bg-success" : "bg-danger"
              });
        })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onAnimationDone(){

    }

    salaryTabChange(event){

    }

    markUnTouched(){
        this.salaryinvoiceForm.markAsUntouched()
    }

    markTouched(){
        this.salaryinvoiceForm.markAllAsTouched()
    }

    validateMinMaxDate(event){
        switch(event.targetElement.id){
            case "salaryFrom": {
                this.dateEnd = event.value
                break;
            }
            case "salaryTo": {
                this.dateStartMax = event.value               
                break;
            }
        }
    }

    getsalaryList(){
        let form = {
            employee: this.salaryList.value.team,
            dateFrom: formatDate(this.salaryList.value.periodFrom,"yyyy-MM-dd","en_PL"),
            dateTo: formatDate(this.salaryList.value.periodTo,"yyyy-MM-dd","en_PL")
        }

       this.salary.getPayrollTeam(form)
       .pipe(takeUntil(this.ngUnsubscribe))
       .subscribe(resp=>{
           this.salaryListArr = <any> resp.body
           this.salaryListArr.sort(function(a,b){
               let ac = a.payrollId
               let bc = b.payrollId
               return bc - ac
           })
       })        
        this.salaryListObj.resetForm()
    }

    triggerPayment(id,event){
        let form = {
            "PayrollId": id,
            "HasPaid": event.checked
        }
        this.salary.updatePayroll(form)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
        });
        
    }
}
